import React from "react";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";
import { Link } from "react-router-dom";

const Help = ({ match }) => {
  return (
    <>
      <div className="help-info">
        <h2 className="tips-title">Dicas</h2>
        Confira algumas dicas abaixo. Se houver alguma sugestão ou dúvida, entre
        em contato conosco.
        <Link to={`${match.url}/dicas/traje`}>
          <div className="tips-menu-1">Traje</div>
        </Link>
        <Link to={`${match.url}/dicas/hoteis`}>
          <div className="tips-menu-2">Hotéis</div>
        </Link>
        <Link to={`${match.url}/dicas/salao-de-beleza`}>
          <div className="tips-menu-3">Salão de Beleza</div>
        </Link>
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender
        content={
          <BottomCenter
            className="tips-center-text"
            text="Entre em contato:  fabio_harada@hotmail.com"
          />
        }
      />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default Help;
