import React from "react";
import styled from "styled-components";

const ErrorForm = ({ className, message, ...props }) => (
  <span className={className} {...props}>
    {message}
  </span>
);

export default styled(ErrorForm)`
  font-size: 12px !important;
  color: #d0021b !important;
  display: block;
  opacity: 0;
  padding-top: 4px;
  animation: fadeIn 700ms ease-in-out 0.15s both;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
