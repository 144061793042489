import React from "react";
import styled from "styled-components";

const FormGroup = ({ className, children, ...props }) => (
  <div className={className} {...props}>
    {children}
  </div>
);

export default styled(FormGroup)`
  margin-bottom: 16px;
`;
