import React from "react";
import styled from "styled-components";

const Button = ({
  className,
  children,
  onClick,
  type = "button",
  disabled,
  small,
  id,
  bgColor,
  fontColor,
  fontHoverColor,
  bgHoverColor,
  borderHoverColor,
  isSubmitting,
  ...props
}) => (
  <button
    className={className}
    disabled={disabled || isSubmitting}
    type={type}
    id={id}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export default styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  background-image: none;
  border-radius: 30px;
  height: ${({ small }) => (small ? 40 : 50)}px;
  transition: all ease 0.4s;
  font-weight: 700;
  width: 50%;
  border: 1px solid rgba(151, 151, 151, 0.2);
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#fff")};
  color: ${({ fontColor }) => (fontColor ? fontColor : "#8e8e8e")};
  padding: 0 42px;

  &:disabled {
    cursor: not-allowed;

    &:hover {
      background-color: ${({ bgHoverColor }) =>
        bgHoverColor ? bgHoverColor : "#E38BA2"};
      border-color: ${({ borderHoverColor }) =>
        borderHoverColor ? borderHoverColor : "#E38BA2"};
      cursor: not-allowed;
    }
  }

  &:hover,
  &:focus {
    border-color: ${({ borderHoverColor }) =>
      borderHoverColor ? borderHoverColor : "#E38BA2"};
    background-color: ${({ bgHoverColor }) =>
      bgHoverColor ? bgHoverColor : "#E38BA2"};
    color: ${({ fontHoverColor }) =>
      fontHoverColor ? fontHoverColor : "#fff"};
  }
`;
