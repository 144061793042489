import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import FormGroup from "./FormGroup";
import FormLabel from "./FormLabel";
import Button from "./Button";
import * as Yup from "yup";
import InputForm from "./InputForm";
import ErrorForm from "./ErrorForm";
import axios from "axios";

const InviteThirdStep = ({ setNextStep, selectedInvite }) => {
  const initialValues = [
    ...Array(selectedInvite.invites_quantity).keys()
  ].reduce((prev, cur) => ({ ...prev, [cur]: "" }), {});
  const validationShape = [
    ...Array(selectedInvite.invites_quantity).keys()
  ].reduce((prev, cur) => ({ ...prev, [cur]: Yup.string() }), {});
  console.log(initialValues);
  console.log(validationShape);
  const handleSubmit = (values, actions) => {
    const data = {
      bissexto_token: selectedInvite.id,
      invites: Object.keys(values).reduce(
        (prev, cur) =>
          values[cur]
            ? [...prev, { invite_name: values[cur], is_child: false }]
            : prev,
        []
      )
    };
    return axios
      .post("https://api.bissexto.be/wedding/invites", data)
      .then(() => actions.setSubmitting(false))
      .then(setNextStep)
      .catch(() => actions.setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape(validationShape)}
    >
      {({ isValid, submitCount, handleSubmit, isSubmitting }) => (
        <form
          className={!isValid && submitCount > 0 ? "displayErrors" : ""}
          onSubmit={handleSubmit}
        >
          <FormGroup>
            <FormLabel>Qual o nome dos convidados?</FormLabel>
            {[...Array(selectedInvite.invites_quantity).keys()].map(
              fieldName => (
                <FormGroup key={fieldName}>
                  <Field name={fieldName} key={`${fieldName}__field`}>
                    {({ field }) => (
                      <InputForm
                        {...field}
                        small={selectedInvite.invites_quantity >= 5}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name={fieldName}
                    key={`${fieldName}__err`}
                    render={msg => <ErrorForm message={msg} />}
                  />
                </FormGroup>
              )
            )}
          </FormGroup>
          <Button type="submit" isSubmitting={isSubmitting}>
            Continuar
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default InviteThirdStep;
