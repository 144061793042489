import React from "react";

const BottomCenter = ({
  className = "center-text",
  text = "「ここまで主が私達を助けて下さった」"
}) => {
  return <div className={className}>{text}</div>;
};

export default BottomCenter;
