import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BottomRight = () => {
  return (
    <div className="right-us">
      <LazyLoadImage src="https://files.bissexto.be/right-us.png" alt="Us" />
    </div>
  );
};

export default BottomRight;
