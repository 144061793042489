import React from "react";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";

const ClothesHelp = () => {
  return (
    <>
      <div className="monetary-tips-info">
        <h2 className="tips-title">Traje</h2>
        Sinta-se à vontade para escolher a sua vestimenta.
        <br />
        Caso tenha dúvida, o dress code para a nossa festa é TRAJE SOCIAL.
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender
        content={
          <BottomCenter
            className="tips-center-text"
            text="Entre em contato:  fabio_harada@hotmail.com"
          />
        }
      />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default ClothesHelp;
