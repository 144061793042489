import React from "react";
import styled from "styled-components";

const RadioButton = ({
  className,
  id,
  name,
  label,
  value,
  onChange,
  onBlur,
  checked,
  ...props
}) => {
  return (
    <label className={className} htmlFor={id}>
      <input
        type="radio"
        onChange={onChange}
        onBlur={onBlur}
        checked={checked}
        value={value}
        name={name}
        id={id}
        {...props}
      />
      <div className={`${className}__a`}></div>
      {label}
    </label>
  );
};

export default styled(RadioButton)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 16px 0;
  font-size: 16px;

  input {
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    z-index: 10;
    white-space: nowrap;
    padding: 0;
    &:checked + div:after {
      opacity: 1;
    }
  }

  &__a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
`;
