import React from "react";
import styled from "styled-components";

const FormLabel = ({ className, children, id, alternate, ...props }) => {
  return (
    <label className={className} htmlFor={id} {...props}>
      {children}
    </label>
  );
};

export default styled(FormLabel)`
  display: block;
  margin-bottom: 8px;

  font-family: Armata;
  font-style: normal;
  font-weight: normal;
  font-size: calc(1vw + 2vmin);
  line-height: 4vh;

  color: #000000;
`;
