import React from "react";
import UnderConstruction from "react-under-construction";
import "react-under-construction/build/css/index.css";

const UnderConstructionApp = () => (
  <UnderConstruction
    background={{
      image: "https://files.bissexto.be/PIXNIO-295222-2850x1901.jpeg",
      textColor: "#fff",
      overlay: {
        color: "#000",
        opacity: ".5"
      }
    }}
    title={{
      text: "Bissexto"
    }}
    description={{
      text: "Our website is under construction. We'll be here soon",
      style: {
        maxWidth: "440px"
      }
    }}
  />
);

export default UnderConstructionApp;
