import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BottomLeft = () => {
  return (
    <div className="left-component">
      <div className="circle red hidden-mobile"></div>
      <LazyLoadImage
        className="left-fabio"
        src="https://files.bissexto.be/left-fabio.png"
        alt="Fabio"
      />
      <LazyLoadImage
        className="left-natalia"
        src="https://files.bissexto.be/left-natalia.png"
        alt="Natalia"
      />
    </div>
  );
};

export default BottomLeft;
