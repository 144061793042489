import React from "react";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";

const MonetaryTips = () => {
  return (
    <>
      <div className="monetary-tips-info">
        <h2 className="tips-title">Presente Monetário</h2>
        Na tradição japonesa, é habitual presentear os noivos em dinheiro,
        geralmente entregue em um envelope especial (Shūgi-bukuro). É um
        presente cheio de significados, representando uma nova vida após o
        matrimônio. Você não é obrigado a nos presentear pessoalmente com notas
        bonitas no envelope. Para facilitar, veja nossa conta bancária abaixo.
        <br />
        Banco Santander
        <br />
        Agência 1087
        <br />
        Conta 01006613-7
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender
        content={
          <BottomCenter
            className="tips-center-text"
            text="Entre em contato:  fabio_harada@hotmail.com"
          />
        }
      />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default MonetaryTips;
