import React from "react";
import { NavLink } from "react-router-dom";

const Menu = ({ match }) => {
  const menuClassName = "menu-item";
  return (
    <div className="menu">
      <ul className="menu-items">
        <li>
          <NavLink
            exact
            className={menuClassName}
            to={`${match.url}/`}
            activeStyle={{ fontWeight: "bold", color: "#2207C7" }}
          >
            Início
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            className={menuClassName}
            to={`${match.url}/confirmar-presenca`}
            activeStyle={{ fontWeight: "bold", color: "#2207C7" }}
          >
            Confimar Presença
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            className={menuClassName}
            to={`${match.url}/lista-de-presente`}
            activeStyle={{ fontWeight: "bold", color: "#2207C7" }}
          >
            Lista de Presente
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            className={menuClassName}
            to={`${match.url}/dicas`}
            activeStyle={{ fontWeight: "bold", color: "#2207C7" }}
          >
            Dicas
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
