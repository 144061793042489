import React from "react";
import { Link } from "react-router-dom";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";

const Home = ({ match }) => {
  return (
    <>
      <div className="home-info">
        Olá, bem vindo ao nosso site de casamento!
        <br />
        Aqui você poderá{" "}
        <Link to={`${match.url}/confirmar-presenca`}>
          Confirmar sua Presença
        </Link>{" "}
        para nossa cerimônia, além de conferir dicas enquanto se prepara para o
        nosso grande dia.
        <br />
        Estamos felizes em poder celebrar nosso casamento com você!
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender content={<BottomCenter />} />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default Home;
