import React from "react";
import InputForm from "./InputForm";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormGroup from "./FormGroup";
import FormLabel from "./FormLabel";
import Button from "./Button";
import axios from "axios";
import ErrorForm from "./ErrorForm";

const InviteFirstStep = ({ setNextStep, setNextStepData }) => {
  const handleSubmit = ({ invite_name }, actions) =>
    axios
      .get("https://api.bissexto.be/wedding/invites/tokens", {
        params: { q: invite_name }
      })
      .then(({ data }) => data.tokens)
      .then(token => setNextStepData(token))
      .then(() => actions.setSubmitting(false))
      .then(setNextStep)
      .catch(() => actions.setSubmitting(false));

  return (
    <Formik
      initialValues={{ invite_name: "" }}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        invite_name: Yup.string().required(
          "Informe o nome que está no convite!"
        )
      })}
    >
      {({
        errors,
        isValid,
        submitCount,
        touched,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          className={!isValid && submitCount > 0 ? "displayErrors" : ""}
          onSubmit={handleSubmit}
        >
          <FormGroup>
            <FormLabel>Qual o nome que está no seu convite?</FormLabel>
            <Field name="invite_name">
              {({ field }) => (
                <InputForm
                  {...field}
                  hasError={errors.invite_name && !touched.invite_name}
                />
              )}
            </Field>
            <ErrorMessage
              name="invite_name"
              render={msg => <ErrorForm message={msg} />}
            />
          </FormGroup>
          <Button type="submit" isSubmitting={isSubmitting}>
            Continuar
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default InviteFirstStep;
