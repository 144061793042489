import React from "react";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";

const OtherTips = () => {
  return (
    <>
      <div className="monetary-tips-info">
        <h2 className="tips-title">Outros Presentes</h2>
        Aceitamos presentes de cama, mesa e banho. Para mais sugestões, entre em
        contato com nossas sogras (paitrocinadores).
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender
        content={
          <BottomCenter
            className="tips-center-text"
            text="Entre em contato:  fabio_harada@hotmail.com"
          />
        }
      />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default OtherTips;
