import React, { useState } from "react";
import InviteFirstStep from "../InviteFirstStep";
import InviteSecondStep from "../InviteSecondStep";
import InviteThirdStep from "../InviteThirdStep";
import InviteFourthStep from "../InviteFourthStep";

const Invite = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [foundInvites, setFoundInvites] = useState([]);
  const [selectedInvite, setSelectedInvite] = useState({});
  const steps = [
    <InviteFirstStep
      setNextStep={() => setCurrentStep(1)}
      setNextStepData={setFoundInvites}
    />,
    <InviteSecondStep
      setNextStep={() => setCurrentStep(2)}
      foundInvites={foundInvites}
      setNextStepData={setSelectedInvite}
    />,
    <InviteThirdStep
      setNextStep={() => setCurrentStep(3)}
      selectedInvite={selectedInvite}
    />,
    <InviteFourthStep />
  ];

  return <div className="invite-info">{steps[currentStep]}</div>;
};

export default Invite;
