import React from "react";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";
import { Link } from "react-router-dom";

const Tips = ({ match }) => {
  return (
    <>
      <div className="tips-info">
        <h2 className="tips-title">Lista de Presente</h2>
        A sua Presença é o nosso presente.
        <br />
        Mas se desejar dar um presente a mais, veja algumas sugestões, clicando
        abaixo:
        <Link to={`${match.url}/lista-de-presente/presente-monetario`}>
          <div className="tips-menu-1">Presente Monetário</div>
        </Link>
        <Link to={`${match.url}/lista-de-presente/cartao-presente`}>
          <div className="tips-menu-2">Cartão-Presente</div>
        </Link>
        <Link to={`${match.url}/lista-de-presente/outros`}>
          <div className="tips-menu-3">Outros Presentes</div>
        </Link>
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender
        content={
          <BottomCenter
            className="tips-center-text"
            text="Entre em contato:  fabio_harada@hotmail.com"
          />
        }
      />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default Tips;
