import React from "react";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";

const BeautySalonHelp = () => {
  return (
    <>
      <div className="beauty-salon-info">
        <h2 className="tips-title">Salão de Beleza</h2>
        <ul>
          <li>
            <div className="underline-text uppercase-text">
              Salão Linda Beauty Place
            </div>
            https://facebook.com/Linda-Beauty-Place-711196949025352
            <br />
            Contato:&emsp;(11) 4607-8898
          </li>
          <br />
          <li>
            <div className="underline-text uppercase-text">
              Espaço Neide Medina
            </div>
            https://www.instagram.com/espaconeidemedina
            <br />
            Contato:&emsp;(11) 2816-8579&emsp;&emsp;(11) 2816-8581
          </li>
          <br />
          <li>
            <div className="underline-text uppercase-text">
              Donna Studio de Beleza
            </div>
            https://donnastudiodebeleza.com.br
            <br />
            Contato:&emsp;(19) 99627-3678&emsp;&emsp;(19) 3849-4646
          </li>
        </ul>
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender
        content={
          <BottomCenter
            className="tips-center-text"
            text="Entre em contato:  fabio_harada@hotmail.com"
          />
        }
      />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default BeautySalonHelp;
