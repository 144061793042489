import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Menu from "./Menu";
import Title from "./Title";
import WeddingInfo from "./WeddingInfo";
import Home from "./pages/Home";
import Invite from "./pages/Invite";
import Tips from "./pages/Tips";
import MonetaryTips from "./pages/MonetaryTips";
import GiftCardTips from "./pages/GiftCardTips";
import OtherTips from "./pages/OtherTips";
import Help from "./pages/Help";
import BeautySalonHelp from "./pages/BeautySalonHelp";
import HotelsHelp from "./pages/HotelsHelp";
import ClothesHelp from "./pages/ClothesHelp";

const WeddingApp = ({ match }) => {
  const className = "bissexto-app";
  console.log(match)
  return (
    <Router>
      <Switch>
        <Route path={`${match.path}/lista-de-presente/outros`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <OtherTips />
          </div>
        </Route>
        <Route path={`${match.path}/lista-de-presente/cartao-presente`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <GiftCardTips />
          </div>
        </Route>
        <Route path={`${match.path}/lista-de-presente/presente-monetario`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <MonetaryTips />
          </div>
        </Route>
        <Route path={`${match.path}/dicas/salao-de-beleza`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <BeautySalonHelp />
          </div>
        </Route>
        <Route path={`${match.path}/dicas/hoteis`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <HotelsHelp />
          </div>
        </Route>
        <Route path={`${match.path}/dicas/traje`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <ClothesHelp />
          </div>
        </Route>
        <Route path={`${match.path}/confirmar-presenca`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <Invite />
          </div>
        </Route>
        <Route path={`${match.path}/lista-de-presente`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <Tips match={match} />
          </div>
        </Route>
        <Route path={`${match.path}/dicas`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <Help match={match} />
          </div>
        </Route>
        <Route path={`${match.path}/`}>
          <div className={className}>
            <Title />
            <Menu match={match} />
            <WeddingInfo />
            <Home match={match} />
          </div>
        </Route>
      </Switch>
    </Router>
  );
};

export default WeddingApp;
