import React from "react";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";

const HotelsHelp = () => {
  return (
    <>
      <div className="monetary-tips-info">
        <h2 className="tips-title">Hotéis</h2>
        Para você que vem de longe e procura um lugar para pousar, veja algumas
        sugestões de hotel em Jundiaí:
        <ul className="dash-list-style">
          <li>São Carlos Eventos e Hospedagem</li>
          <li>Hotel Center Park</li>
          <li>Ibis Budget Jundiaí Shopping</li>
          <li>Adagio Jundiai Shopping</li>
        </ul>
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender
        content={
          <BottomCenter
            className="tips-center-text"
            text="Entre em contato:  fabio_harada@hotmail.com"
          />
        }
      />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default HotelsHelp;
