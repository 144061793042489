import React from "react";
import MaskedInput from "react-text-mask";
import styled from "styled-components";

const InputForm = ({
  type = "text",
  value,
  id,
  name,
  placeholder,
  disabled,
  required,
  onBlur,
  hasError = false,
  mask = false,
  showMask = true,
  pipe,
  guide,
  onChange,
  className,
  ...props
}) => {
  return (
    <MaskedInput
      className={className}
      value={value}
      type={type}
      id={id}
      name={name}
      mask={mask}
      showMask={showMask}
      pipe={pipe}
      guide={guide}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      onBlur={onBlur}
      onChange={onChange}
      {...props}
    />
  );
};

export default styled(InputForm)`
  width: 70% !important;
  height: ${props => (props.small ? "20px" : "40px")};
  padding-left: 16px;
  background-color: #f5f5f5;
  border: 1px solid #ebebeb;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  font-size: 16px;
  border-radius: 5px;
  color: #4a4a4a !important;
  border-color: ${props => (props.hasError ? "#f72407" : "#ebebeb")}!important;

  &:disabled {
    background: #eaeaea;
    /* color: #9b9b9b; */
  }

  &:focus {
    border-color: ${props =>
      props.hasError ? "#f72407" : "#ebebeb"}!important;
    outline: 0;
    outline: thin dotted \9;
    box-shadow: 0 0 2px #66afe9;
  }

  &::placeholder {
    font-size: 16px !important;
  }
`;
