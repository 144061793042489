import React from "react";
import RadioButton from "./RadioButton";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormGroup from "./FormGroup";
import FormLabel from "./FormLabel";
import ErrorForm from "./ErrorForm";
import Button from "./Button";

const InviteSecondStep = ({ setNextStep, foundInvites, setNextStepData }) => {
  const handleSubmit = ({ selected_invite }, actions) => {
    actions.setSubmitting(false);
    setNextStepData(foundInvites[selected_invite]);
    setNextStep();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validateOnChange={false}
      initialValues={{ selected_invite: "" }}
      validationSchema={Yup.object().shape({
        selected_invite: Yup.number()
          .integer()
          .required("Informe o nome que está no convite!")
      })}
    >
      {({ isValid, submitCount, handleSubmit, isSubmitting }) => (
        <form
          className={!isValid && submitCount > 0 ? "displayErrors" : ""}
          onSubmit={handleSubmit}
        >
          <FormGroup>
            <FormLabel>Qual o nome que está no seu convite?</FormLabel>
            <Field name="selected_invite">
              {({ field }) =>
                foundInvites.map(({ invite_name }, key) => (
                  <RadioButton
                    {...field}
                    key={key}
                    label={invite_name}
                    value={key}
                  />
                ))
              }
            </Field>
            <ErrorMessage
              name="selected_invite"
              render={msg => <ErrorForm message={msg} />}
            />
            <Button type="submit" isSubmitting={isSubmitting}>
              Continuar
            </Button>
          </FormGroup>
        </form>
      )}
    </Formik>
  );
};

export default InviteSecondStep;
