import React from "react";
import LazilyRender from "react-lazily-render";
import BottomLeft from "../BottomLeft";
import BottomCenter from "../BottomCenter";
import BottomRight from "../BottomRight";

const GiftCardTips = () => {
  return (
    <>
      <div className="monetary-tips-info">
        <h2 className="tips-title">Cartão-Presente</h2>
        Adorariamos ganhar vale-presente de alguma loja de casa-decoração,
        eletrodomésticos, viagem, serviço... Sinta-se à vontade para nos
        presentear.
      </div>
      <LazilyRender content={<BottomLeft />} />
      <LazilyRender
        content={
          <BottomCenter
            className="tips-center-text"
            text="Entre em contato:  fabio_harada@hotmail.com"
          />
        }
      />
      <LazilyRender content={<BottomRight />} />
    </>
  );
};

export default GiftCardTips;
