import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UnderConstructionApp from "./UnderConstructionApp";
import WeddingApp from "./WeddingApp";

const App = () => (
  <Router>
    <Switch>
      <Route path="/wedding" component={WeddingApp} />
      <Route path="/" component={UnderConstructionApp} />
    </Switch>
  </Router>
);

export default App;
