import React from "react";
import arrow from "./arrow.svg";

const WeddingInfo = () => {
  return (
    <div>
      <div className="wedding-info">29 de fevereiro de 2020</div>
      <div className="wedding-place">
        <img className="arrow" src={arrow} alt="arrow" /> sábado, em jundiaí
      </div>
    </div>
  );
};

export default WeddingInfo;
